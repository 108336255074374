<template>
    <div class="pa-0">
        <div class="interaction-section-body1">
          <hb-form label="Allow Merchandise Management" :active.sync="active" description="Allow Merchandise Management" @save="save" editable :empty="false">
            <template v-slot:display>
              <v-col class="ma-0 pa-0" cols="12" sm="12">{{value ? 'Yes' : 'No'}}</v-col>
            </template>
            <template v-slot:edit>
              <div>
                <hb-switch right v-model="value" :label="value ? 'Yes' : 'No'"></hb-switch>
              </div>
            </template>
          </hb-form>
        </div>
    </div>
</template>

<script type="text/babel">

import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from  '@/mixins/notificationMixin.js';


export default {
    name: 'MerchandiseSettings',
    mixins: [ notificationMixin ],
    props: {
        isMerchandiseEnable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value: false,
            active: ''
        };
    },
    created() {
        this.value = this.isMerchandiseEnable;
    },
    computed: {
        ...mapGetters({
            merchandiseSettings: 'productStore/merchandiseSettings'
        }),
    },
    methods: {
        ...mapActions({
            saveMerhcandiseSettings: 'productStore/setMerchandiseSettingsAction',
            getMerchandiseSettingsAction: 'productStore/getMerchandiseSettingsAction'
        }),
        async save() {
            let formData = {
                settings : { allowMerchandiseManagement: this.value ? true : false },
                role_id : this.getInterPropertyRoleId
            };
            await this.saveMerhcandiseSettings(formData);
            this.showMessageNotification({ type: 'success', description: 'Settings saved successfully.' });
            this.active = 'saved';
        }
	},
    watch: {
        merchandiseSettings(new_value) {
            this.value = new_value;
        }
    }
};
</script>